<template>
  <div id="homeContent" :style="homeBackground">
    <div class="home-bg-mask"></div>
    <!-- <div class="column-help-line" style="transform: translateX(455px);"></div>
    <div class="column-help-line" style="transform: translateX(1066px);"></div>
    <div class="column-help-line" style="transform: translateX(1474px);"></div> -->
    <div class="home-3d-logo">
      <logoword v-for="(e,i) in logoWords"
                :key="i"
                ref="lw"
                :word="e.word"
                :elName="e.el"
                :coordinate="e.coordinate"
                :position="e.position"
                :imgIndex="imgIndex"></logoword>
    </div>
    <div class="flip-header home-header">
      <!-- <div class="flip-header-btn home-header-tab" :style="{backgroundImage:'url('+headerIcons[0]+')'}" @click="handleLogoImage()"></div> -->
      <div class="flip-header-btn home-header-tab"
           @click="this.$store.commit('chgLang')"
           :style="$store.state.isEn?{backgroundImage:'url('+headerIcons[1]+')'}:{backgroundImage:'url('+headerIcons[3]+')'}"></div>
      <div v-if="!isExpand"
           class="flip-header-btn home-header-tab"
           :style="{backgroundImage:'url('+headerIcons[2]+')'}"
           @mouseover="isExpand=true">
        <!-- @mouseleave="isExpand=false"> -->
      </div>
      <div v-else
           class="home-header-tab">
        <span class="search-contain"
              @mouseleave="!isFocus?isExpand=false:'' ">
          <input class="search-input"
                 :class="$store.state.isEn?'fontStyle4':'fontStyle4Cn'"
                 type="text"
                 :placeholder="$store.state.isEn?searchPlaceHolder:searchPlaceHolderCn"
                 v-model="searchValue"
                 @focus="handleFocus"
                 @blur="handleBlur"
                 @keydown="handleSearch($event)">
        </span>
      </div>
    </div>
    <div class="home-footer fontStyle1">
      <div class="home-footer-item"
           @click="clickStudio()"
           @mouseover="flipWord('#studioEnter', 0)"
           @mouseleave="flipWord('#studioEnter', 1)">
        <span id="studioEnter">FLIP Studio</span>
        <!-- <span class="home-footer-icon" :style="{backgroundImage:'url('+arrowIcon+')'}"></span> -->
      </div>
      <div class="home-footer-item"
           @click="clickPop()"
           @mouseover="flipWord('#popEnter', 0)"
           @mouseleave="flipWord('#popEnter', 1)">
        <span id="popEnter">FLIP POP</span>
        <!-- <span class="home-footer-icon" :style="{backgroundImage:'url('+arrowIcon+')'}"></span> -->
      </div>
    </div>
  </div>
</template>

<script>
// import StericalComp from 'components/common/StericalComp'
// import Logoword from '@/components/common/logoword/logoword.vue';
import gsap from 'gsap';


// F: 400 L: 1020 I:1510
const setFlipIndex = e => {
  // console.log(e.pageX)
  let els = document.getElementsByClassName('logo-word')
  let winWidth = window.innerWidth
  let winHeight = window.innerHeight
  if (winWidth >= 768) {
    if ((e.pageX / winWidth) * 100 <= (455/1920)*100) { // 455px
      els[0].style.setProperty('z-index', 999)
      els[1].style.setProperty('z-index', 99)
      els[2].style.setProperty('z-index', 9)
      els[3].style.setProperty('z-index', 0)
    }
    if ((e.pageX / winWidth) * 100 > (455/1920)*100 && (e.pageX / window.innerWidth) * 100 <= (1066/1920)*100) { // 1066px

      els[0].style.setProperty('z-index', 99)
      els[1].style.setProperty('z-index', 999)
      els[2].style.setProperty('z-index', 9)
      els[3].style.setProperty('z-index', 0)
    }
    if ((e.pageX / winWidth) * 100 > (1066/1920)*100 && (e.pageX / window.innerWidth) * 100 <= (1474/1920)*100) { // 1474px
      els[0].style.setProperty('z-index', 99)
      els[1].style.setProperty('z-index', 9)
      els[2].style.setProperty('z-index', 999)
      els[3].style.setProperty('z-index', 0)
    }
    if ((e.pageX / winWidth) * 100 > (1474/1920)*100) {
      els[0].style.setProperty('z-index', 99)
      els[1].style.setProperty('z-index', 9)
      els[2].style.setProperty('z-index', 0)
      els[3].style.setProperty('z-index', 999)
    }
  }else {
    if ((e.pageX / winWidth) * 100 <= 50 && (e.pageY / winHeight) * 100 <= 50) {
      els[0].style.setProperty('z-index', 999)
      els[1].style.setProperty('z-index', 99)
      els[2].style.setProperty('z-index', 9)
      els[3].style.setProperty('z-index', 0)
    }
    if ((e.pageX / winWidth) * 100 > 50 && (e.pageY / winHeight) * 100 <= 60) {

      els[0].style.setProperty('z-index', 99)
      els[1].style.setProperty('z-index', 999)
      els[2].style.setProperty('z-index', 9)
      els[3].style.setProperty('z-index', 0)
    }
    if ((e.pageX / winWidth) * 100 <= 50 && (e.pageY / winHeight) * 100 > 50) {
      els[0].style.setProperty('z-index', 99)
      els[1].style.setProperty('z-index', 9)
      els[2].style.setProperty('z-index', 999)
      els[3].style.setProperty('z-index', 0)
    }
    if ((e.pageX / winWidth) * 100 > 50 && (e.pageY / winHeight) * 100 > 60) {
      els[0].style.setProperty('z-index', 99)
      els[1].style.setProperty('z-index', 9)
      els[2].style.setProperty('z-index', 0)
      els[3].style.setProperty('z-index', 999)
    }
  }

  // console.log(window.innerWidth, e.pageX, e.pageY);
}
export default {
  // components: { StericalCompLogoword },
  data () {
    return {
      headerIcons: [
        require('assets/image/homeSwitch@2x.png'),
        require('assets/image/o_homeLanguage@2x.png'),
        require('assets/image/o_homeSearch@2x.png'),
        require('assets/image/o_homeLanguageEn@2x.png'),
      ],
      arrowIcon: require('assets/image/arrow@2x.png'),
      isExpand: false, // 展开搜索框
      isFocus: false,
      searchValue: '',
      searchPlaceHolder: 'Search',
      searchPlaceHolderCn: '搜索',
      logoWords: [
        {
          word: "F",
          el: "filpLogoWordF",
          coordinate: { // 模型
            x: -137,
            y: -250,
            z: -50
          },
          position: { //相机
            x: -300,
            y: 700,
            z: -300
          }
        },
        {
          word: "L",
          el: "filpLogoWordL",
          coordinate: {
            x: -170,
            y: -230,
            z: -50
          },
          position: {
            x: 350,
            y: 300,
            z: 600
          }
        },
        {
          word: "I",
          el: "filpLogoWordI",
          coordinate: {
            x: -115,
            y: -250,
            z: -50
          },
          position: {
            x: 150,
            y: 400,
            z: 600
          }
        },
        {
          word: "P",
          el: "filpLogoWordP",
          coordinate: {
            x: -150,
            y: -250,
            z: -50
          },
          position: {
            x: 250,
            y: -300,
            z: 600
          }
        },
      ],
      isCover: false,
      homeBackground: {},
      imgIndex: -1,
      imgSrc: 'https://miniapp-1253923639.cos.ap-nanjing.myqcloud.com/flip/',
    }
  },
  methods: {
    handleLogoImage () {
      if (!this.isCover) {
        (this.$refs.lw).forEach(e => { e.addMaterial() })
        this.isCover = !this.isCover
      } else {
        (this.$refs.lw).forEach(e => { e.removeMaterial() })
        this.isCover = !this.isCover
      }
    },
    handleFocus () {
      this.isFocus = true;
      // this.searchPlaceHolder = '';
    },
    handleBlur () {
      this.isExpand = false;
      this.isFocus = false;
    },
    handleSearch (e) {
      if (e.keyCode == 13) {
        console.log('回车搜索', this.searchValue)
        this.$router.push(
          {
            name: 'Search',
            query: {
              value: this.searchValue
            }
          }
        )
      }
    },
    clickStudio () {
      this.$router.push(
        {
          name: 'Studio',
        }
      )
    },
    clickPop () {
      this.$router.push(
        {
          name: 'Pop',
        }
      )
    },
    flipWord (elementId = '', direction = 0) { // flag0 - 反； 1 - 正
      if (direction) {
        gsap.to(elementId, { rotateY: ('0deg'), duration: 0.5 })
        gsap.to(elementId, { color: '#313131', duration: 0.1 })
      } else {
        gsap.to(elementId, { rotateY: ('180deg'), duration: 0.5 })
        gsap.to(elementId, { color: '#00eb96', duration: 0.1 })
      }
    },
    getHomeImage () {
      this.imgIndex = Math.floor((Math.random() * 4) + 1);
      this.homeBackground = {
        // backgroundImage: 'url(' + require('assets/image/home_00'+this.imgIndex+'.jpg') + ')',
        backgroundImage: 'url(' + this.imgSrc + 'home_00'+ this.imgIndex + '.jpg)',
      }
    }
  },
  mounted () {
    this.getHomeImage()
    window.addEventListener('mousemove', setFlipIndex);
  },
  unmounted () {
    window.removeEventListener('mousemove', setFlipIndex);
  }
}
</script>

<style>
</style>